<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-bill-of-lading' }">{{
            $t("MENU.ITEM.ACTIVITIES.BILL_OF_LADING")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingTankCharge"
            with-copy
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <BillOfLadingForm
          ref="BillOfLadingForm"
          :form="form"
        ></BillOfLadingForm>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            <div>{{ error }}</div>
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingBillOfLading"
            with-copy
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_BILL_OF_LADING } from "../store/bill-of-lading.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import KTCard from "@/view/content/Card.vue";
import BillOfLadingForm from "./BillOfLadingForm.vue";
import billOfLadingMixin from "./mixins/billOfLading.mixin";

import { mapGetters, mapState } from "vuex";

export default {
  name: "BillOfLadingAdd",
  mixins: [billOfLadingMixin],
  components: {
    KTCard,
    SaveButtonDropdown,
    BillOfLadingForm,
  },
  props: {
    formData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errors: {},
      form: {
        staffId: null,
        work: [
          {
            subcontractorsLocationId: null,
            note: null,
            serviceId: null,
            measureId: null,
            dateWorked: null,
            serviceQty: null,
          },
        ],
      },
    };
  },
  mounted() {
    if (this.formData) {
      this.form = this.formData;
      this.$refs.BillOfLadingForm.$v.form.$reset();
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ACTIVITIES.BILL_OF_LADING"),
        route: { name: "list-bill-of-lading" },
      },
      { title: this.$i18n.t("LABELS.ADD_NEW") },
    ]);
  },

  computed: {
    ...mapGetters(["isLoadingBillOfLading"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  watch: {},
  methods: {
    onSave(next) {
      let vm = this;
      this.$refs.BillOfLadingForm.$v.form.$touch();
      if (this.$refs.BillOfLadingForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }

      this.checkGpsData(this.form.machine, this.form.work).then(() => {
        this.$store
          .dispatch(SAVE_BILL_OF_LADING, this.form)
          .then((data) => {
            vm.errors = data.data.errors;

            let itemEdit = data.data;
            vm.$notify({
              group: "notify",
              type: "success",
              title: "<i class='flaticon2-checkmark'></i> Success",
              text: data.message,
            });
            // let itemEdit = data.data;
            if (next == "continue") {
              //New
              this.$router.push({
                name: "edit-bill-of-lading",
                params: { id: itemEdit.id },
              });
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-bill-of-lading" });
            }
            if (next == "copy") {
              //New
              this.form.billNumber = null;
              this.$refs.BillOfLadingForm.$v.form.$reset();
            }
            if (next == "exit") {
              this.$router.push({ name: "list-bill-of-lading" });
            }
          })
          .catch((response) => {
            this.errors = this.$errors.format(response.data.errors);

            vm.$notify({
              group: "notify",
              type: "error",
              title: this.$t("ALERTS.ERROR"),
              text: response.data.message,
            });
          });
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style type="text/css">
.modal-dialog {
  min-height: 500px !important;
}
</style>
